.button {
    padding: 10px 20px;
    font: var(--ff);
    color: var(--colour-black);
    background-color: #fff;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    text-align: center;
    outline: none;
}

.button:hover {
    background-color: var(--colour-black);
    color: #fff;
}


@container (min-width: 1400px) {
    h1 {
        font: var(--h1);
    }

    p {
        font: var(--p);
    }

    .column {
        flex: 1;
    }

    .polaroid img {
        width: 30rem;
        height: 30rem;
    }
}